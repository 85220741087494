import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { FaAngellist, FaGoogle, FaInstagram } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { IoIosHelpBuoy, IoIosSpeedometer } from "react-icons/io";
import { MdBrush, MdOndemandVideo } from "react-icons/md";
import Faq from "../components/faq";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import TeamSection from "../components/team-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";

const MortgageBrokerWebDesign = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			needHelpImage: wpMediaItem(title: { eq: "Mortgage Broker Image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			flexImage: wpMediaItem(title: { eq: "Flex MasterLogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ryanImage: wpMediaItem(title: { eq: "Ryan-Dixon-Property-Logo-Master" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Mortgage Broker Web Design" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Mortgage Broker Web Design",
				item: {
					url: `${siteUrl}/mortgage-broker-web-design`,
					id: `${siteUrl}/mortgage-broker-web-design`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/mortgage-broker-web-design`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						page="Mortgage Broker Web Design"
						form
						title={`Mortgage Broker \n<span class="text-secondary">web design</span>`}
						description={
							<span>
								Helping you achieve more with a professionally purpose-built
								website
							</span>
						}
						buttons={[
							<Button
								className="px-4 py-2 bg-white border-white  w-100 cta-btn w-md-auto mt-md-4 me-md-3 fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Book a discovery call
							</Button>,
							// <Button
							// 	className="px-5 py-2 mt-4 border-white  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
							// 	variant="primary"
							// 	href="#price-section"
							// >
							// 	Our pricing
							// </Button>,
						]}
					/>
				</section>

				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-between gx-6">
							<Col lg={6} className="">
								<h2 className="mb-5 display-5 text-primary">
									Build a website that reflects your mortgage brokerage
								</h2>

								<p>
									Create a new website for your mortgage brokerage that puts
									your company in the spotlight. Having a website that has been
									professionally designed by RJM Digital can help you to attract
									new lenders and borrowers like never before.
								</p>

								<p>We are experts in our area and can help you to:</p>

								<ul className="higher">
									<li className="">Improve your mortgage brokers reputation</li>
									<li className="">
										Showcase your knowledge and expertise on a platform that you
										manage and control
									</li>
									<li className="">
										Implement result-generating digital marketing strategies
									</li>
								</ul>

								<p>
									Our specialist mortgage broker web design team will work
									closely with you to ensure your website reflects your brand,
									how you operate in the mortgage broker market and
									differentiate you from the competition.
								</p>

								<Button
									className="px-4 py-2 mt-4  w-100 cta-btn w-md-auto fs-5 white-link-yellow"
									variant="primary"
									href="#form"
								>
									Book a discovery call
								</Button>
							</Col>
							<Col lg={5}>
								<div
									className="mt-5 mt-lg-0"
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100 "
										image={data.needHelpImage.gatsbyImage}
										alt={data.needHelpImage.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center justify-content-center g-5 g-lg-7">
							<Col xs={6} lg={4}>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.flexofficesolutions.co.uk/"
								>
									<GatsbyImage
										image={data.flexImage.gatsbyImage}
										alt={data.flexImage.altText}
										class="w-100"
									/>
								</a>
							</Col>
							<Col xs={6} lg={4}>
								<GatsbyImage
									image={data.ryanImage.gatsbyImage}
									alt={data.ryanImage.altText}
									class="w-100"
								/>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section
					style={{
						background:
							"transparent linear-gradient(49deg, #023A7F 0%, #40B4E5 100%) 0% 0% no-repeat padding-box",
					}}
					className="py-5  py-lg-7"
				>
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-center text-white display-5 mb-lg-7">
									Key Features of our Mortgage Broker websites
								</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdBrush className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Bespoke Design</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Building you a website with a unique look and feel that
									represents your brand and reflects who you are as a mortgage
									brokerage.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosSpeedometer className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Ultra-Fast</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Give your customers an amazing user experience with load
									speeds for each web page to less than 1 second.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaGoogle className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Built for SEO</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Rank higher on Google when your potential customers are
									searching for your services, to help them find you more
									easily.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<IoIosHelpBuoy className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Fully Managed</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									RJM Digital will maintain, support and ensure your website is
									secure, so you can rest assured knowing your website is in
									safe hands.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaAngellist className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Featured Services</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Highlight your customers favourite services that you offer to
									attract new clients’ attention.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiDocumentText className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Upload Useful Docs</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Upload helpful documents to your site such as PDFs to increase
									your customers engagement on your website.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<MdOndemandVideo className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Explainer Videos</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Mortgages are oftentimes a difficult topic for your borrowers,
									so uploading videos can help them to digest information and
									put a friendly face to a hard subject.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<FaInstagram className="text-white display-3" />
								<h3 className="my-4 text-white ssp-bold">Sync Your Socials</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Sync your social media channels to your website to promote
									your content across a variety of platforms.
								</p>
							</Col>
						</Row>
						<Row className="mt-5 w-100 d-flex justify-content-center">
							<Button
								className="px-4 py-2 mt-4 bg-white border-white  w-100 cta-btn w-md-auto fs-5 blue-link-yellow"
								variant="primary"
								href="#form"
							>
								Book a call
							</Button>
						</Row>
					</Container>
				</section>
				{/* <section className="py-5 py-lg-7">
					{" "}
					<Container className="pricing-heading position-relative">
						<Row id="price-section">
							<Col>
								<h2 className="pb-5 text-center text-primary display-5">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className={` justify-content-center `}>
							<PricingCard
								btnColour="secondary"
								title="Essential"
								subText="Brokerages and agents looking for their first website"
								price="£95"
								monthly
								setupFee="£995"
								features={[
									"Templated design",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Fully Managed Website{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>Excludes domain</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"GatsbyJS frontend",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Listings Manager{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Add, edit and remove listings via our Listings
															management tool
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"WordPress Content Management System",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											MapBox Integration{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Involves a usage charge billed directly from
															MapBox
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									"Unlimited Images, Audio and File content",
									"Fast and Secure Web Hosting",
									"SSL Certificate",
									"Custom Forms",
									"Google Tag Manager and Google Analytics",
								]}
							/>
							<PricingCard
								btnColour="dark-blue"
								middle
								title="Premium"
								subText={
									<span>Brokerages looking for a complete listing portal</span>
								}
								price="£195"
								monthly
								setupFee="£1495"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Essential</span>
									</span>,
									"Bespoke design",
									"SEO advice and basic implementation",
									"Monthly Analytics Report",
									"Shortlist functionality",
									"Monthly testing and updates on new browser / OS updates",
									"Lead capture forms and e-mail notifications",
								]}
							/>
							<PricingCard
								btnColour="light-blue"
								title="Pro"
								subText="Brokerages looking for an advanced solution"
								price="£245"
								monthly
								setupFee="£2995"
								features={[
									<span>
										Everything in <span className="ssp-bold"> Premium</span>
									</span>,
									"Fully customisable listings engine",
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Login section for gated content{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Maximum user limits apply
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,
									<span className="d-flex align-items-center">
										<p className="pb-0 mb-0 d-inline-block ssp-regular">
											Multilingual functionality{" "}
											<OverlayTrigger
												placement="top"
												overlay={
													<Popover id={`popover-positioned-top`}>
														<Popover.Body>
															Translator costs charged separately on ad-hoc
															basis
														</Popover.Body>
													</Popover>
												}
											>
												<FaInfoCircle
													style={{ width: "10px", color: "#40B4E5" }}
													className="ms-2 d-inline-block"
												/>
											</OverlayTrigger>
										</p>
									</span>,

									"Social media feed sync",
									"Integration with your CRM",
									"Progressive Web App",
									"Advanced SEO advice and implementation",
									"Conversion Rate Optimisation recommendations each month",
								]}
							/>
						</Row>
					</Container>
				</section> */}

				<WhyChooseRJMSection
					experienced="Having an experienced team to design your new website can help to increase the possibility of attracting new leads of higher quality. At RJM Digital we have over 5 years of experience in the web design industry and have continually built websites for our customers that reflect their brand and help to distinguish themselves in their market."
					bespoke="All the websites we design and build are unique to the specific customer it was created for. We strive to produce websites that are high quality and stand out from the crowd."
					provenResults="Typically marketing agencies say they can deliver results but don't usually have the evidence to support their claim. However, each of our customers has seen an improvement since RJM Digital launched their new sites, and their reviews demonstrate this."
					reliable="Along your journey with RJM Digital, we will continually be by your side for any help, assistance or guidance you may need."
					transparent="At every stage in the web design process of your new mortgage broker website we will be open in everything that we do and where we are in the design and development phases. We will always be available for a call or meeting regarding any questions you might have."
					trustworthy="We have worked hard over the years to build long-lasting relationships with our customers around the globe, and we have achieved this through keeping aligned with our core values of being reliable, transparent and results-driven."
				/>

				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your Mortgage Broker web design project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>

				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2sae!4v1673980072743!5m2!1sen!2sae" />
				<section className="my-6">
					<TeamSection />
				</section>
				<div id="faq">
					<Faq page="Real Estate Dubai" />
				</div>
				<OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/>
			</Layout>
		</>
	);
};

export default MortgageBrokerWebDesign;
